import React from "react";
import Footer from "./Footer";

export function LandingPage() {
    return (
        <div className="chapter-container">
            <div className="centered-content">
                <h1>Poznámky ke stochastickému programování</h1>
                <em>(a k matematice celkově)</em>
                <br />
                <br />
                <em>Pavel Popela (zdroj moudrosti), Jan Novotný (zapisovatel)</em>

                <p>Ve vývoji...</p>

                <Footer />
            </div>
        </div>
    );
}
