import React, { useState } from "react";

import { createBrowserRouter, Outlet, RouterProvider, useLocation } from "react-router-dom";

import "./App.css";
import type { Section } from "./defs";
import { sections } from "./defs";
import { FooterMini } from "./Footer";
import { LandingPage } from "./LandingPage";
import { ContentsMenu } from "./Menu";
import { NavBar } from "./NavBar";
import { Path } from "./paths";

import { version } from "./version";
import Login from "./LoginPage";

const AppLayout = () => {
    const location = useLocation();
    const isHistogramApp = location.pathname.includes("histogram-app");

    return (
        <div className="App" style={{ display: "flex", gap: 0, width: "100vw" }}>
            <div className="sidebar" style={{ display: "flex", flexDirection: "column" }}>
                <NavBar />
                <div className="menu-container" style={{ flexGrow: 1 }}>
                    <ContentsMenu sections={sections} />
                </div>
                <div className="small-text">version {version}</div>
                <FooterMini />
            </div>
            <div className="page" style={{ flexBasis: isHistogramApp ? "0" : "6", flexGrow: 6 }}>
                <Outlet />
            </div>
        </div>
    );
};

function routerChild(section: Section) {
    return {
        path: section.path,
        element: section.element(section.sectionNumber),
    };
}

const router = createBrowserRouter([
    {
        path: Path.root,
        element: <AppLayout />,
        children: [
            {
                path: "",
                element: <LandingPage />,
            },
            // routerChild(BisectionMethodMeta),
        ],
    },
]);

export default function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    return <div>{isLoggedIn ? <RouterProvider router={router} /> : <Login onLogin={() => setIsLoggedIn(true)} />}</div>;
}
