import React, { useState } from "react";
import CryptoJS from "crypto-js";

const users = [
    { username: "jn", passwordHash: "a442b8b51c109069921b1a104ea21e83" }, // md5 -s "..."
    { username: "pp", passwordHash: "a442b8b51c109069921b1a104ea21e83" },
];

const Login: React.FC<{ onLogin: () => void }> = ({ onLogin }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const handleLogin = () => {
        const passwordHash = CryptoJS.MD5(password).toString();
        const isValidUser = users.some((user) => user.username === username && user.passwordHash === passwordHash);

        if (isValidUser) {
            onLogin();
        } else {
            setError("Invalid username or password");
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            handleLogin(); // Trigger login on Enter key press
        }
    };

    return (
        <div>
            <h1>Login</h1>
            <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown} // Listen for Enter key press
            />
            <button onClick={handleLogin}>Login</button>
            {error && <p>{error}</p>}
        </div>
    );
};

export default Login;
