// import {
//     BisectionMethodMeta,
// } from "./chapters";

export interface Section {
    title: string;
    shortTitle: string;
    path: string;
    element: (sectionNumber: string) => JSX.Element;
    sectionNumber: string;
}

export interface MenuItem {
    section: Section;
    menuitems?: MenuItem[];
}

function m(section: Section, sections?: Section[]): MenuItem {
    if (sections === undefined) {
        return {
            section: section,
        };
    }
    return {
        section: section,
        menuitems: sections.map((s) => m(s)),
    };
}

// ORDER MATTERS! This defines the order of items in the menu (!)
export const sections: MenuItem[] = [
    // m(SectionMathFoundationsMeta, [FunctionsNotesMiniMeta, MatricesNotesMeta, GoniometricMeta]),
];

function setSectionNumbers(menuitems: MenuItem[], prefix = "") {
    let n = 1;
    for (const m of menuitems) {
        m.section.sectionNumber = prefix + (prefix ? "." : "") + n.toString();
        // console.log("Setting section number", s.sectionNumber, "for", s.title);
        if (m.menuitems !== undefined) {
            setSectionNumbers(m.menuitems, m.section.sectionNumber);
        }
        n += 1;
    }
}

setSectionNumbers(sections, "");
